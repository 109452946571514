export const fakeModalScreens = [
  "/",
  "/forgotpassword",
  "/resetpassword",
  "/groupaddedit",
  "/memberaddedit",
  "/consoleuseradd",
  "/useraddedit",
  "/consoleaddedit",
  "/controlleraddedit",
  {
    path: "/account/changepassword",
    exact: true,
  },
  {
    path: "/account/setup2fa",
    exact: true,
  },
  {
    path: "/account/disable2fa",
    exact: true,
  },
]

export const navigationlinks = {
  superadmin: [
    {
      name: "Groups",
      url: "/groups",
    },
    {
      name: "Users",
      url: "/users",
    },
    {
      name: "Devices",
      url: "/devices",
    },
    // {
    //   name: "Controllers",
    //   url: "/controllers",
    // },
    {
      name: "Audit Logs",
      url: "/auditlogs",
    },
    {
      name: "Account",
      url: "/account",
    },
  ],
}
